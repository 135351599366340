import { Amplify } from "aws-amplify";
import { Authenticator, Flex, Button, Alert } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import "./App.css";
import { useState } from 'react';

const data_bucket_name: string = process.env.REACT_APP_DATA_S3_BUCKET || "";
const region: string = process.env.REACT_APP_WEB_APP_REGION || "";
const identity_pool_id: string = process.env.REACT_APP_IDENTITY_POOL_ID || "";
const user_pool_id: string = process.env.REACT_APP_USER_POOL_ID || "";
const user_pool_client_id: string = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || "";

type ProcessFileFunction = (params: { file: File; key: string }) => Promise<{ key: string; file: File }>;

const processFile: ProcessFileFunction = async ({ file, key }) => {
  return { key, file, useAccelerateEndpoint: true };
}

const amplify_config = {
  "aws_project_region": region,
  "aws_cognito_identity_pool_id": identity_pool_id,
  "aws_user_pools_id": user_pool_id,
  "aws_user_pools_web_client_id": user_pool_client_id,
  "aws_cognito_region": region,
  "aws_user_files_s3_bucket": data_bucket_name,
  "aws_user_files_s3_bucket_region": region,
  "aws_cognito_username_attributes": ["EMAIL"],
  "aws_cognito_mfa_configuration": "ON",
}

Amplify.configure(amplify_config, {
  Storage: {
    S3: {
      prefixResolver: async ({ accessLevel, targetIdentityId }) => {
        if (accessLevel === 'protected') {
          return 'uploads/';
        } else {
          return `unknown/${targetIdentityId}/`;
        }
      }
    }
  }
});

function Header() {
  return (
    <header>
      <img src="/logo.png" alt="Ponant logo" className="logo" />
    </header>
  );
}

interface FailedFile {
  key: string;
  error: string;
}

interface AlertProps {
  onClose: () => void;
  failedFiles: FailedFile[];
}

export const AccessibleAlert: React.FC<AlertProps> = ({ onClose, failedFiles }) => {
  return (
    <Flex direction="column">
      {failedFiles.map((file, index) => (
        <Alert key={index} variation="error">
          <strong>{file.key}</strong>: {file.error}
        </Alert>
      ))}
      <Button onClick={onClose}>Close</Button>
    </Flex>
  );
};

export default function App() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [failedFiles, setFailedFiles] = useState<FailedFile[]>([]);

  const handleCloseAlert = () => {
    setIsAlertVisible(false);
    setFailedFiles([]);
  };

  return (
    <>
      <Header />
      <Authenticator
        hideSignUp={true}
      >
        {({ signOut }) => (
          <>
            <div className="storage-container">
              <h1 className="upload-heading">Upload Satisfaction Surveys</h1>
              <div className="warnings">
                <p>
                  For a seamless submission process, please adhere to the following guidelines:
                </p>
                <ul>
                  <li><b>File format</b>: All files must be in PDF.</li>
                  <li><b>Content</b>: It should exclusively contain satisfaction surveys in the correct order.</li>
                  <li><b>Orientation</b>: The pages within the PDF files must be in portrait orientation.</li>
                  <li><b>Page limit</b>: Each PDF file should not exceed a maximum of 200 pages.</li>
                  <li><b>Headers</b>: Carefully fill out all required header fields of each survey.</li>
                  <li><b>Timezone</b>: Make sure your device's clock is synced up with the actual time.</li>
                </ul>
              </div>
              <StorageManager
                acceptedFileTypes={[".pdf"]}
                accessLevel="protected"
                autoUpload={false}
                maxFileCount={10}
                processFile={processFile}
                onUploadError={(error, { key }) => {
                  setIsAlertVisible(true);
                  setFailedFiles(prevFailedFiles => [...prevFailedFiles, { key, error: error }]);
                }}
              />
              <div className="alert-container">
                {isAlertVisible && (
                  <AccessibleAlert
                    onClose={handleCloseAlert}
                    failedFiles={failedFiles}
                  />
                )}
              </div>
            </div>
            <div className="sign-out-container">
              <button className="sign-out-button" onClick={signOut}>
                <span>Sign out</span>
              </button>
            </div>
          </>
        )}
      </Authenticator>
    </>
  )
}
